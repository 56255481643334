import React, { useCallback, useRef, useState } from "react";
import { HellaFast } from "screens/HBHome/HellaFast";
import { Box } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { FormData } from "../../useGetStarted";
import AddressType from "typedef/Address";
import useAddressAutoComplete from "components/CAddressAutocomplete/useAddressAutocomplete";
import API from "utils/API";
import { useLocation, useNavigate } from "react-router-dom";
import Loan from "typedef/Loan";
import { InputAddress } from "./InputAddress";
import { useHBManualAddress } from "components/HBManualAddress/useHBManualAddress";

type BodyRegisterLoan = {
  city: string | undefined;
  state: string | undefined;
  street_line: string | undefined;
  secondary: string | undefined;
  zipcode: string | undefined;
  marketingCampaign?: Loan["marketingCampaign"];
  customAddress?: AddressType;
};

interface AddressProps {
  onNext: () => void;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  loan?: Loan;
}

export const Address = ({
  onNext,
  formData,
  setFormData,
  loan,
}: AddressProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const marketingCampaignString: string | null =
    localStorage.getItem("marketingCampaign");
  const marketingCampaign = marketingCampaignString
    ? JSON.parse(marketingCampaignString)
    : undefined;

  const [loadingRegisterLoan, setLoadingRegisterLoan] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [hasSelectedAddress, setHasSelectedAddress] = useState(false);
  const [isManualAddressSelected, setIsManualAddressSelected] = useState(false);
  const [manualAddressExists, setManualAddressExists] = useState(
    Boolean(formData.customAddress?.street_name),
  );
  const addressType: AddressType | undefined = undefined;
  const isBorrowerAddress = false;

  const isCustomAddress: AddressType & {
    street_name?: string;
    street_number?: string;
    street_suffix?: string;
  } = {
    city: formData?.customAddress?.city?.trim() ?? "",
    state: formData?.customAddress?.state?.trim() ?? "",
    street_line:
      formData.customAddress?.street_number &&
      formData.customAddress?.street_name
        ? `${formData?.customAddress?.street_number?.trim()} ${formData?.customAddress?.street_name?.trim()}${
            formData?.customAddress?.street_suffix?.length > 0
              ? ` ${formData?.customAddress?.street_suffix?.trim()}`
              : ""
          }`
        : "",
    secondary: formData?.customAddress?.secondary?.trim() ?? "",
    zipcode: formData.customAddress?.zipcode?.trim() ?? "",
    street_name: formData?.customAddress?.street_name?.trim() ?? "",
    street_number: formData?.customAddress?.street_number?.trim() ?? "",
    street_suffix: formData?.customAddress?.street_suffix?.trim() ?? "",
    entries: 0,
  };

  const {
    errors,
    setErrors,
    manualAddressInputsToValidate,
    validateManualAddressInputs,
    onValueChange,
  } = useHBManualAddress({ formData, setFormData, isBorrowerAddress });

  const isAddress = {
    city: formData?.city?.trim(),
    state: formData?.state?.trim(),
    street_line: formData?.street_line?.trim(),
    secondary: formData?.secondary?.trim(),
    zipcode: formData?.zipcode?.trim(),
    entries: formData.entries ?? 0,
  };

  const [isAlreadyAddress, setIsAlreadyAddress] = useState(
    Boolean(isAddress.street_line) ||
      Boolean(formData.customAddress?.street_name),
  );

  const [currentAddress, setCurrentAddress] = useState<AddressType>({
    city: "",
    state: "",
    street_line: "",
    secondary: "",
    zipcode: "",
    entries: 0,
  });

  const [animation, setAnimation] = useState(false);

  const valueInputRef = useRef<HTMLInputElement>(null);

  const { text } = useAddressAutoComplete({
    address: addressType,
    setAddress: setCurrentAddress as React.Dispatch<AddressType | null>,
  });

  const handleNext = async () => {
    if (
      !currentAddress?.street_line &&
      !isAlreadyAddress &&
      !hasSelectedAddress
    ) {
      setAnimation(true);
      setError(true);
      setErrorMessage("Please enter a property");
      if (!text.length && error) {
        valueInputRef.current?.focus();
      } else if (text.length && error) {
        setErrorMessage("Please select a property");
      }
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else if (hasSelectedAddress && isManualAddressSelected) {
      const inputsErrors = validateManualAddressInputs(
        manualAddressInputsToValidate,
      );

      if (inputsErrors?.length > 0) {
        setErrors([...inputsErrors]);
        setAnimation(true);
        setTimeout(() => {
          setAnimation(false);
        }, 300);
        return;
      } else {
        setManualAddressExists(true);
        setErrorMessage("");
        setFormData({
          ...formData,
          city: "",
          state: "",
          street_line: "",
          secondary: "",
          zipcode: "",
          entries: 0,
        });
        const data = await registerLoan({
          body: {
            city: undefined,
            state: undefined,
            zipcode: undefined,
            street_line: undefined,
            secondary: undefined,
            customAddress: isCustomAddress,
            marketingCampaign: marketingCampaign
              ? marketingCampaign
              : loan?.marketingCampaign,
          },
        });

        if (data?.loanId) {
          localStorage.setItem("DTCApplication__loanId", data.loanId);
          navigate(location.pathname);
          onNext();
        }
      }
    } else if (isAlreadyAddress) {
      onNext();
    } else {
      setManualAddressExists(false);
      setError(false);
      setErrorMessage("");
      setFormData({
        ...formData,
        customAddress: undefined,
        city: currentAddress?.city,
        state: currentAddress?.state,
        street_line: currentAddress?.street_line,
        secondary: currentAddress?.secondary ?? "",
        zipcode: currentAddress?.zipcode,
      });
      const data = await registerLoan({
        body: {
          city: currentAddress?.city,
          state: currentAddress?.state,
          street_line: currentAddress?.street_line,
          secondary: currentAddress?.secondary ?? "",
          zipcode: currentAddress?.zipcode,
          marketingCampaign: marketingCampaign
            ? marketingCampaign
            : loan?.marketingCampaign,
        },
      });

      if (data?.loanId) {
        localStorage.setItem("DTCApplication__loanId", data.loanId);
        localStorage.removeItem("marketingCampaign");
        navigate(location.pathname);
        onNext();
      }
    }
  };

  const registerLoan = useCallback(
    async (params: { body: BodyRegisterLoan }) => {
      try {
        const loanIdByStorge = localStorage.getItem("DTCApplication__loanId");
        setLoadingRegisterLoan(true);
        const { body } = params;
        const response = await API.post<{ body: string }>({
          url: `/register-user/loan${
            loanIdByStorge ? `?loanId=${loanIdByStorge}` : ""
          }`,
          data: {
            ...body,
          },
        });
        if ("error" in response) {
          throw new Error(response.error);
        } else {
          setLoadingRegisterLoan(false);
          return JSON.parse(response.data.body);
        }
      } catch (error) {
        const err = error as Error;
        setError(true);
        setErrorMessage(
          err.message ? err.message : "An error occurred, please try again.",
        );
        setLoadingRegisterLoan(false);
      }
    },
    [],
  );
  const showUsePropertyMessage = isAlreadyAddress ? (
    <>
      <strong className="strong-900">
        {manualAddressExists
          ? addressToString(isCustomAddress)
          : addressToString(isAddress as AddressType)}
      </strong>{" "}
      will be used to secure your HELOC. If this is correct, simply click Next
    </>
  ) : undefined;
  return (
    <Box>
      <Box>
        <HellaFast
          title={
            <>
              The Homebridge HELOC <br />
              Get started in minutes!
            </>
          }
        />
      </Box>
      <Box
        className="get-started__wrapper get-started__address"
        style={{
          fontFamily: "NunitoSans",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <InputAddress
          formData={formData}
          inputLabel="What’s the address of the property?"
          currentAddress={currentAddress}
          setCurrentAddress={setCurrentAddress}
          setIsAlreadyAddress={setIsAlreadyAddress}
          valueInputRef={valueInputRef}
          error={error}
          setError={setError}
          animation={animation}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          addressToString={addressToString}
          setHasSelectedAddress={setHasSelectedAddress}
          isManualAddress={isManualAddressSelected}
          setIsManualAddress={setIsManualAddressSelected}
          isBorrowerAddress={isBorrowerAddress}
          errors={errors}
          onValueChange={onValueChange}
        />
        <p className="get-started__note" style={{ marginBottom: "40px" }}>
          {isAlreadyAddress
            ? showUsePropertyMessage
            : "This is the property you’ll use to secure your HELOC."}
        </p>
        <CHBButton
          onClick={handleNext}
          loading={loadingRegisterLoan}
          ctaId="Step1Address"
        >
          Next
        </CHBButton>
      </Box>
      <HBSeparate className="step-separate" type="vertical" />
    </Box>
  );
};

export function addressToString(incomingAddress: {
  city: string;
  state: string;
  street_line: string;
  secondary?: string;
  zipcode: string;
  entries?: number;
}): string {
  const address = { ...incomingAddress };
  if (!address) return "";
  let whiteSpace = "";
  if (address.secondary) {
    if ((address.entries ?? 0) > 1) {
      address.secondary += ` (${address.entries})`;
    }
    whiteSpace = " ";
  }
  if (address.street_line === "My address is not listed") {
    return address.street_line;
  } else {
    return `${address.street_line}${whiteSpace}${address.secondary}, ${address.city}, ${address.state}, ${address.zipcode}`;
  }
}
