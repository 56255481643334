import { Box } from "@mui/material";
import { FormData } from "screens/GetStarted/useGetStarted";
import { ManualInputErrors } from "./useHBManualAddress";
import CHBTextField from "components/CHBTextField";

interface HBManualAddressProps {
  formData: FormData;
  animation: boolean;
  error?: boolean;
  isBorrowerAddress: boolean;
  inputErrors: ManualInputErrors[];
  onValueChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => void;
}
export const HBManualAddress = ({
  formData,
  inputErrors,
  error,
  animation,
  isBorrowerAddress,
  onValueChange,
}: HBManualAddressProps) => {
  const address = isBorrowerAddress
    ? formData?.customBorrowerAddress
    : formData?.customAddress;

  return (
    <>
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"flex-start"}
        flexWrap={{ xs: "wrap", sm: "wrap", md: "nowrap" }}
        justifyContent={{
          xs: "space-evenly",
          sm: "space-between",
          md: "space-between",
        }}
        gap={2}
        marginTop={4}
      >
        <Box width={{ xs: "47.125%", sm: "25%", md: "45%" }}>
          <CHBTextField
            id={"street_number"}
            label={"Street Number"}
            value={address?.street_number}
            placeholder={"Street number"}
            onChange={onValueChange}
            error={
              inputErrors.find((err) => err.field === "street_number")?.message
            }
            hasError={
              inputErrors.find((err) => err.field === "street_number") || error
                ? true
                : false
            }
            animation={animation as boolean}
            newFontSize="12px"
          />
        </Box>
        <Box width={{ xs: "47.125%", sm: "32%", md: "62%" }}>
          <CHBTextField
            id={"street_name"}
            label={"Street Name"}
            value={address?.street_name}
            placeholder={"Street name"}
            onChange={onValueChange}
            error={
              inputErrors.find((err) => err.field === "street_name")?.message
            }
            hasError={
              inputErrors.find((err) => err.field === "street_name") || error
                ? true
                : false
            }
            animation={animation as boolean}
            newFontSize="12px"
          />
        </Box>
        <Box width={{ xs: "47.125%", sm: "18%", md: "36%" }}>
          <CHBTextField
            id={"street_suffix"}
            label={"Street Suffix"}
            value={address?.street_suffix}
            placeholder={"Ln, Rd, St..."}
            onChange={onValueChange}
            error={
              inputErrors.find((err) => err.field === "street_suffix")?.message
            }
            hasError={
              inputErrors.find((err) => err.field === "street_suffix") || error
                ? true
                : false
            }
            animation={animation as boolean}
            newFontSize="12px"
          />
        </Box>
        <Box width={{ xs: "47.125%", sm: "15%", md: "30%" }}>
          <CHBTextField
            id={"secondary"}
            label={"Apt/Suite"}
            value={address?.secondary}
            placeholder={"Unit #"}
            onChange={onValueChange}
            error={
              inputErrors.find((err) => err.field === "secondary")?.message
            }
            hasError={
              inputErrors.find((err) => err.field === "secondary") || error
                ? true
                : false
            }
            animation={animation as boolean}
            newFontSize="12px"
          />
        </Box>
      </Box>
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"flex-start"}
        flexWrap={{ xs: "wrap", sm: "wrap", md: "nowrap" }}
        justifyContent={"space-between"}
        gap={2}
        marginTop={2}
        marginBottom={2}
      >
        <Box width={{ xs: "100%", sm: "35%", md: "50%" }}>
          <CHBTextField
            id={"city"}
            label={"City"}
            value={address?.city}
            placeholder={"City"}
            onChange={onValueChange}
            error={inputErrors.find((err) => err.field === "city")?.message}
            hasError={
              inputErrors.find((err) => err.field === "city") || error
                ? true
                : false
            }
            animation={animation as boolean}
            newFontSize="12px"
          />
        </Box>
        <Box width={{ xs: "47.125%", sm: "32.5%", md: "50%" }}>
          <CHBTextField
            id={"zipcode"}
            label={"Zipcode"}
            value={address?.zipcode}
            placeholder={"Zipcode"}
            onChange={onValueChange}
            error={inputErrors.find((err) => err.field === "zipcode")?.message}
            hasError={
              inputErrors.find((err) => err.field === "zipcode") || error
                ? true
                : false
            }
            animation={animation as boolean}
            newFontSize="12px"
          />
        </Box>
        <Box width={{ xs: "47.125%", sm: "25%", md: "35%" }}>
          <CHBTextField
            id={"state"}
            label={"State"}
            value={address?.state}
            placeholder={"State"}
            onChange={(e) => {
              e.target.value = e.target.value.toUpperCase();
              onValueChange(e);
            }}
            error={inputErrors.find((err) => err.field === "state")?.message}
            hasError={
              inputErrors.find((err) => err.field === "state") || error
                ? true
                : false
            }
            animation={animation as boolean}
            newFontSize="12px"
          />
        </Box>
      </Box>
    </>
  );
};
