import { env } from "env";
import React, { useState } from "react";
import HomeIcon from "assets/Icons/HomeIcon";
import FacebookIcon from "assets/Icons/FacebookIcon";
import TwitterIcon from "assets/Icons/TwitterIcon";
import LinkedinIcon from "assets/Icons/LinkedinIcon";
import InstagramIcon from "assets/Icons/InstagramIcon";
import PhoneAndMapsIcons from "assets/Icons/PhoneAndMapsIcons";
import CTermsDialog from "../CTermsDialog";
import {
  Box,
  Link,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import useInvite from "context/Invite/useInviteContext";
import { PLTermsAndPolicyLinks } from "./PLTermsAndPolicyLinks";

const CFooter: React.FC = () => {
  const theme = useTheme();
  const pathname = window.location.pathname;

  const isLongFooter =
    pathname === "/" ||
    pathname === "/faqs" ||
    pathname === "/lenders" ||
    pathname === "*";

  const [termsIsOpen, setTermsIsOpen] = useState(false);

  const [enviromentSelected, setEnviroment] = useState(
    localStorage.getItem("currentEviroment") ?? "DEV",
  );
  const { privateLabel } = usePrivateLabel();

  const isPriorityPL =
    privateLabel?.id === "PRIVATE#LABEL#test.priorityheloc.com" ||
    privateLabel?.id === "PRIVATE#LABEL#priorityheloc.com";
  const isPowerPayPL =
    privateLabel?.id === "PRIVATE#LABEL#test.powerpayloans.com" ||
    privateLabel?.id === "PRIVATE#LABEL#powerpayloans.com";

  const isSupremePL =
    privateLabel?.id === "PRIVATE#LABEL#test.equitynowsupremelending.com" ||
    privateLabel?.id === "PRIVATE#LABEL#equitynowsupremelending.com";

  if (pathname === "/faqs") {
    window.location.replace(
      "https://help.nftydoor.com/hc/en-us/categories/20824511618199-FAQs",
    );
  }
  const invite = useInvite();

  const showTextFooter =
    (!invite &&
      ["/", "/login", "/contactus", "/signup", "/faqs"].includes(pathname)) ||
    (invite &&
      (pathname.startsWith("/invite/") ||
        ["/", "/login", "/contactus", "/signup", "/faqs"].includes(pathname)));

  const footerBody = privateLabel?.borrowerPortalConfiguration
    ?.customHomePageContent?.footerBody ? (
    privateLabel.borrowerPortalConfiguration.customHomePageContent.footerBody
  ) : (
    <>
      Eligibility is subject to completion of an application and verification of
      home ownership, occupancy, title, income, employment, credit, home value,
      collateral, and underwriting requirements. Loan program terms and
      conditions will depend on underwriting and consumer credit
      characteristics. This is not a commitment to lend or offer of credit.
    </>
  );

  const footerLicensesLink = privateLabel?.borrowerPortalConfiguration
    ?.customHomePageContent?.footerLicensesLink
    ? privateLabel.borrowerPortalConfiguration.customHomePageContent
        .footerLicensesLink
    : "licenses";

  const footerExtra = privateLabel?.borrowerPortalConfiguration
    ?.customHomePageContent?.footerExtra
    ? privateLabel.borrowerPortalConfiguration.customHomePageContent.footerExtra
    : "Equal Housing Lender";

  const footerNYAuthorizedPL =
    privateLabel?.borrowerPortalConfiguration?.customHomePageContent
      ?.NYAuthorizedFooter;

  const isValidNYSite =
    privateLabel?.newYorkApplicationsDomain &&
    privateLabel?.newYorkApplicationsDomain?.includes(window.location.hostname);

  return (
    <Container
      maxWidth={isLongFooter ? "lg" : "md"}
      sx={{
        my: "2rem",
        width: { xs: "90vw" },
        margin: { xs: "2rem auto 0 auto" },
        maxWidth: "840px",
      }}
      disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={{ md: "start", xs: "center" }}
        flexDirection={{ md: "row", xs: "column" }}
        gap={{ xs: isSupremePL ? 3 : 0, md: 0 }}
      >
        {!isPowerPayPL && !isPriorityPL && (
          <>
            <Box sx={{ alignItems: { xs: "center" } }}>
              {privateLabel?.footerPolicy?.footerLink ? (
                <Link
                  href={privateLabel.footerPolicy.footerLink}
                  sx={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography>
                    {privateLabel?.isNFTYDoor
                      ? `C ${new Date().getFullYear()} ${privateLabel
                          ?.footerPolicy?.footerRow}`
                      : privateLabel?.footerPolicy?.footerRow}
                  </Typography>
                </Link>
              ) : (
                <Typography>
                  {privateLabel?.isNFTYDoor
                    ? `C ${new Date().getFullYear()} ${privateLabel
                        ?.footerPolicy?.footerRow}`
                    : privateLabel?.footerPolicy?.footerRow}
                </Typography>
              )}
              {isSupremePL && (
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <PhoneAndMapsIcons color={theme.palette.text.secondary} />
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"3px"}
                    mt={"2px"}
                  >
                    <Typography
                      color={theme.palette.text.secondary}
                      variant="subtitle2"
                    >
                      14801 Quorum Drive Suite 300 Dallas, TX 75254
                    </Typography>
                    <Typography
                      color={theme.palette.text.secondary}
                      variant="subtitle2"
                    >
                      877.350.5225
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>

            {isSupremePL ? (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={{ xs: "center", md: "end" }}
                gap={3}
              >
                <Box
                  display={"flex"}
                  justify-content={"space-between"}
                  alignItems={"center"}
                  gap={2}
                >
                  <Link
                    href={"https://www.facebook.com/SupremeLendingHQ/"}
                    sx={{ textDecoration: "none", color: "inherit" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon />
                  </Link>
                  <Link
                    href={"https://x.com/SupremeLending"}
                    sx={{ textDecoration: "none", color: "inherit" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterIcon />
                  </Link>
                  <Link
                    href={"https://www.instagram.com/supremelendinghq"}
                    sx={{ textDecoration: "none", color: "inherit" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon />
                  </Link>
                  <Link
                    href={"https://www.linkedin.com/company/supremelending/"}
                    sx={{ textDecoration: "none", color: "inherit" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedinIcon />
                  </Link>
                </Box>
                <PLTermsAndPolicyLinks
                  privateLabel={privateLabel}
                  footerLicensesLink={footerLicensesLink}
                  setTermsIsOpen={setTermsIsOpen}
                />
              </Box>
            ) : (
              <PLTermsAndPolicyLinks
                privateLabel={privateLabel}
                footerLicensesLink={footerLicensesLink}
                setTermsIsOpen={setTermsIsOpen}
              />
            )}
          </>
        )}
      </Box>
      {isSupremePL && <Divider sx={{ my: 3 }} />}
      <Box
        my={1}
        width={"100%"}
        display="flex"
        justifyContent="space-between"
        alignItems={{ md: "start", xs: "center" }}
        flexDirection={{ md: "row", xs: "column" }}
      >
        <Typography
          color={theme.palette.text.secondary}
          variant="subtitle2"
          p={{ xs: 2, md: 0 }}
          textAlign={"justify"}
        >
          {footerBody}
        </Typography>
      </Box>
      {!isPowerPayPL && !isPriorityPL && (
        <Box
          display="flex"
          width={"100%"}
          justifyContent="space-between"
          alignItems={{ md: "start", xs: "center" }}
          flexDirection={{ md: "row", xs: "column" }}
        >
          <Box
            display="flex"
            alignItems={{ md: "start", xs: "center" }}
            flexDirection={{ md: "row", xs: "column" }}
          >
            <Typography
              sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
              noWrap={true}
            >
              {`C ${new Date().getFullYear()} ${
                privateLabel?.footerPolicy?.footerRow ??
                privateLabel?.lenderName
              }`}
            </Typography>
            <Typography mx={0.8} display={{ xs: "none", md: "block" }}>
              |
            </Typography>
            <Link
              sx={{
                cursor: "pointer",
                color: theme.palette.text.primary,
                textDecoration: "none",
              }}
              href={`https://www.nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/COMPANY/${privateLabel?.NMLScompany}`}
              target="_blank"
              data-acsb-tooltip="NewWindow"
              data-acsb-clickable={true}
              data-acsb-navigable={true}
              data-acsb-now-navigable={true}
            >
              <span
                className="acsb-sr-only"
                data-acsb-sr-only={true}
                data-acsb-force-visible={true}
                aria-hidden={false}
                data-acsb-hidden={false}
              >
                New Window
              </span>
              <Typography noWrap={true}>
                NMLS ID #{privateLabel?.NMLScompany}
              </Typography>
            </Link>
            <Link
              sx={{
                ml: 1,
                cursor: "pointer",
                color: theme.palette.primary.main,
                textDecoration: "none",
              }}
              href="https://www.nmlsconsumeraccess.org"
              target="_blank"
            >
              <Typography noWrap={true}> www.nmlsconsumeraccess.org</Typography>
            </Link>
          </Box>
        </Box>
      )}
      <Box
        display={"flex"}
        flexDirection={{
          xs: isPowerPayPL || isPriorityPL ? "column" : "row",
          sm: "row",
        }}
        mt={{ xs: 0, md: 1 }}
        mb={isPowerPayPL || isPriorityPL ? 2 : 0}
        sx={{
          alignItems: "center",
          justifyContent:
            isPowerPayPL || isPriorityPL
              ? "space-between"
              : { xs: "center", md: "left" },
          gap: 2,
          px: { sm: 2, md: 0 },
        }}
      >
        <Box
          display={"flex"}
          mt={{ xs: 0, md: isPowerPayPL || isPriorityPL ? 1 : 0 }}
          sx={{
            alignItems: "center",
            justifyContent: { xs: "center", md: "left" },
          }}
        >
          {privateLabel?.mediaResources?.footerLogo ? (
            <Box p={0}>
              <img
                src={privateLabel?.mediaResources?.footerLogo}
                alt="Footer logo"
                style={{
                  height: 80,
                }}
                className="acsb-sr-only"
                data-acsb-force-visible={true}
                aria-hidden={false}
                data-acsb-hidden={false}
              />
            </Box>
          ) : (
            <HomeIcon style={{ transform: "translateY(0.3rem)" }} />
          )}
          <Typography noWrap={true}>{footerExtra}</Typography>
        </Box>
        {(isPowerPayPL || isPriorityPL) && (
          <PLTermsAndPolicyLinks
            privateLabel={privateLabel}
            footerLicensesLink={footerLicensesLink}
            setTermsIsOpen={setTermsIsOpen}
          />
        )}
      </Box>

      <Box
        my={1}
        width={"100%"}
        display="flex"
        justifyContent="space-between"
        alignItems={{ md: "start", xs: "center" }}
        flexDirection={{ md: "row", xs: "column" }}
      >
        <Typography color={theme.palette.text.primary} p={{ xs: 2, md: 0 }}>
          {privateLabel?.footerPolicy?.NYstateAuthorizedSite === false &&
          !isValidNYSite &&
          showTextFooter
            ? footerNYAuthorizedPL?.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  <span dangerouslySetInnerHTML={{ __html: line }} />
                  <br />
                </React.Fragment>
              ))
            : null}
        </Typography>
      </Box>
      {process.env.REACT_APP_ENV === "prod" ? null : (
        <Box>
          <Box display="flex" justifyContent="end">
            <Typography>
              Current version: {process.env.REACT_APP_CURRENT_VERSION}{" "}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="end">
            <select
              value={enviromentSelected}
              onChange={(e) => {
                localStorage.setItem("currentEviroment", e.target.value);
                setEnviroment(e.target.value);
                window.location.reload();
              }}
              onSelect={(e) => {
                console.log(e);
              }}
            >
              {Object.keys(env).map((items, index) => (
                <option key={index} value={items}>
                  {items}
                </option>
              ))}
            </select>
          </Box>
        </Box>
      )}

      <CTermsDialog
        open={termsIsOpen}
        handleClose={() => setTermsIsOpen(false)}
      />
    </Container>
  );
};

export default CFooter;
