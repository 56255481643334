import AddressType from "typedef/Address";
import React, { useEffect, useState } from "react";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { Box, Typography } from "@mui/material";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import useAddressAutoComplete from "components/CAddressAutocomplete/useAddressAutocomplete";
import { FormData } from "screens/GetStarted/useGetStarted";
import { HBManualAddress } from "components/HBManualAddress";
import { ManualInputErrors } from "components/HBManualAddress/useHBManualAddress";

interface InputAddressProps {
  formData: FormData;
  inputLabel: string;
  currentAddress?: AddressType;
  setCurrentAddress: React.Dispatch<React.SetStateAction<AddressType>>;
  setIsAlreadyAddress?: (isAlreadyAddress: boolean) => void;
  valueInputRef: React.RefObject<HTMLInputElement>;
  error: boolean;
  setError: (error: boolean) => void;
  animation: boolean;
  errorMessage?: string;
  setErrorMessage?: (error: string) => void;
  addressToString: (address: AddressType) => string;
  hasSelectedAddress?: boolean;
  setHasSelectedAddress: (hasSelectedAddress: boolean) => void;
  isManualAddress: boolean;
  setIsManualAddress: React.Dispatch<React.SetStateAction<boolean>>;
  isBorrowerAddress: boolean;
  onValueChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => void;
  errors: ManualInputErrors[];
}

export const InputAddress = ({
  formData,
  inputLabel,
  currentAddress,
  setCurrentAddress,
  setIsAlreadyAddress,
  valueInputRef,
  error,
  setError,
  animation,
  errorMessage,
  setErrorMessage,
  addressToString,
  hasSelectedAddress,
  setHasSelectedAddress,
  isManualAddress,
  setIsManualAddress,
  isBorrowerAddress,
  onValueChange,
  errors,
}: InputAddressProps) => {
  const [openSuggestions, setOpenSuggestions] = useState(false);

  const { fourthText } = useHomeBridgeColors();
  const { sizes } = useHomebridgeSizes();

  const {
    text,
    setText,
    handleOnInputChange,
    handleOnChange,
    options,
    loading,
  } = useAddressAutoComplete({
    address: currentAddress,
    setAddress: setCurrentAddress as React.Dispatch<AddressType | null>,
    isShowManualAddress: true,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    handleOnInputChange(e, e.target.value, "input");
    setOpenSuggestions(true);
    setHasSelectedAddress(false);
    if (
      inputValue.trim().length === 0 ||
      inputValue !== "My address is not listed"
    ) {
      setIsManualAddress(false);
    }
  };

  useEffect(() => {
    if (!text || text?.length === 0) {
      setCurrentAddress({
        city: "",
        state: "",
        street_line: "",
        secondary: "",
        zipcode: "",
        entries: 0,
      });
      setOpenSuggestions(false);
    }
  }, [text, setCurrentAddress]);

  const handleFocus = () => {
    if (
      !hasSelectedAddress &&
      !isManualAddress &&
      (text || text.trim().length > 0)
    ) {
      setOpenSuggestions(true);
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (hasSelectedAddress || isManualAddress || !isBorrowerAddress) {
        setOpenSuggestions(false);
      }
    }, 250);
  };

  useEffect(() => {
    if (
      currentAddress?.entries &&
      currentAddress?.entries > 1 &&
      options.length > 0
    ) {
      setOpenSuggestions(true);
    }
  }, [currentAddress?.entries, options.length]);

  return (
    <>
      <Typography
        variant="body2"
        fontFamily={"NunitoSans"}
        fontSize={sizes.typography}
        marginBottom={"20px"}
        fontWeight={400}
      >
        {inputLabel}
      </Typography>

      <Box
        sx={{
          width: "100%",
          maxWidth: "600px",
        }}
      >
        <input
          ref={valueInputRef}
          type="text"
          value={text}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={{
            border:
              error &&
              errors.length >= 0 &&
              currentAddress?.street_line !== "My address is not listed"
                ? "2px solid red"
                : `2px solid ${fourthText}`,
            animation:
              animation &&
              errors.length >= 0 &&
              currentAddress?.street_line !== "My address is not listed"
                ? "shake 0.3s ease-in-out"
                : "none",
          }}
        />
        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {loading && (
            <p
              className="spinner"
              style={{
                padding: "10px",
                margin: 0,
              }}
            ></p>
          )}
        </Box>

        {options.length > 0 && openSuggestions && (
          <Box
            sx={{
              marginTop: "10px",
              backgroundColor: "#102939",
              border: "1px solid #20A2D8",
              borderTop: "none",
            }}
          >
            {options.map((option, index) => (
              <Box
                className="get-started__address__option"
                style={{
                  borderBottom: "1px solid #20A2D8",
                  padding: "10px",
                }}
                key={`${option.street_line}-${option.city}`}
                onClick={(e) => {
                  handleOnChange(e, option, "selectOption");

                  if (option.street_line === "My address is not listed") {
                    setText("My address is not listed");
                    setIsManualAddress(true);
                    setOpenSuggestions(false);
                  } else {
                    setText(addressToString(option));
                    setIsManualAddress(false);
                    setOpenSuggestions(false);
                  }

                  setErrorMessage?.("");
                  setError(false);
                  setIsAlreadyAddress?.(false);
                  setHasSelectedAddress(true);
                }}
              >
                {addressToString(option)}
              </Box>
            ))}
          </Box>
        )}
        {isManualAddress && (
          <HBManualAddress
            formData={formData}
            animation={animation}
            error={error}
            isBorrowerAddress={isBorrowerAddress}
            inputErrors={errors}
            onValueChange={onValueChange}
          />
        )}
      </Box>
      {text.length >= 0 && (
        <Typography
          variant="body2"
          fontFamily={"NunitoSans"}
          fontSize={"16px"}
          color={"error"}
          marginTop={"10px"}
          sx={{
            animation: animation ? "shake 0.3s ease-in-out" : "none",
          }}
        >
          {errorMessage !== "" ? errorMessage : ""}
        </Typography>
      )}
    </>
  );
};
