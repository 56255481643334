import { Box, Link, Typography } from "@mui/material";
import { PrivateLabel } from "typedef/PrivateLabel";

interface PLTermsAndPolicyLinksProps {
  privateLabel: PrivateLabel | undefined;
  footerLicensesLink: string;
  setTermsIsOpen: (status: boolean) => void;
}

export const PLTermsAndPolicyLinks = ({
  privateLabel,
  footerLicensesLink,
  setTermsIsOpen,
}: PLTermsAndPolicyLinksProps) => {
  return (
    <Box display="flex">
      {privateLabel?.footerPolicy.privacyTerms === undefined ||
      privateLabel?.footerPolicy?.privacyTerms === "" ? (
        <Link
          onClick={() => setTermsIsOpen(true)}
          sx={{ mr: 1, cursor: "pointer", textDecoration: "none" }}
          tabIndex={0}
          role="button"
          data-acsb-clickable={true}
          data-acsb-navigable={true}
          data-acsb-now-navigable={true}
        >
          <Typography
            data-acsb-clickable={true}
            data-acsb-navigable={true}
            data-acsb-now-navigable={true}
          >
            {privateLabel?.borrowerPortalConfiguration?.customHomePageContent
              ?.footerTermsOfUse ?? "Terms of Use"}
          </Typography>
        </Link>
      ) : (
        <Link
          href={privateLabel?.footerPolicy.privacyTerms}
          sx={{ mr: 1, cursor: "pointer", textDecoration: "none" }}
          tabIndex={0}
          target="_blank"
          role="button"
          data-acsb-clickable={true}
          data-acsb-navigable={true}
          data-acsb-now-navigable={true}
        >
          <Typography
            data-acsb-clickable={true}
            data-acsb-navigable={true}
            data-acsb-now-navigable={true}
          >
            {privateLabel?.borrowerPortalConfiguration?.customHomePageContent
              ?.footerTermsOfUse ?? "Terms of Use"}
          </Typography>
        </Link>
      )}
      <Typography>|</Typography>
      <Link
        href={privateLabel?.footerPolicy.privacyPolicyLink}
        target="_blank"
        sx={{ mx: 1, cursor: "pointer", textDecoration: "none" }}
        tabIndex={0}
        role="button"
        data-acsb-clickable={true}
        data-acsb-navigable={true}
        data-acsb-now-navigable={true}
      >
        <Typography
          data-acsb-clickable={true}
          data-acsb-navigable={true}
          data-acsb-now-navigable={true}
        >
          {privateLabel?.borrowerPortalConfiguration?.customHomePageContent
            ?.footerPrivacyPolicy ?? "Privacy Policy"}
        </Typography>
      </Link>
      <Typography>|</Typography>
      <Link
        href={footerLicensesLink}
        sx={{ ml: 1, cursor: "pointer", textDecoration: "none" }}
        tabIndex={0}
        role="button"
        data-acsb-clickable={true}
        data-acsb-navigable={true}
        data-acsb-now-navigable={true}
      >
        <Typography
          data-acsb-clickable={true}
          data-acsb-navigable={true}
          data-acsb-now-navigable={true}
        >
          {privateLabel?.borrowerPortalConfiguration?.customHomePageContent
            ?.footerLicenses ?? "Licenses"}
        </Typography>
      </Link>
    </Box>
  );
};
