import {
  FormData,
  ManualAddressFields,
} from "screens/GetStarted/useGetStarted";
import React, { useState } from "react";
import statesList from "utils/statesList";

export type ManualInputErrors = {
  field: keyof ManualAddressFields;
  message: string;
  success?: boolean;
  regex?: RegExp;
};

interface UseHBManualAddressProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  isBorrowerAddress: boolean;
  setErrorMessage?: React.Dispatch<React.SetStateAction<string>>;
  setError?: (error: boolean) => void;
}

export const useHBManualAddress = ({
  formData,
  setFormData,
  isBorrowerAddress,
  setErrorMessage,
  setError,
}: UseHBManualAddressProps) => {
  type AddressField = keyof (
    | typeof formData.customAddress
    | typeof formData.customBorrowerAddress
  );

  const [errors, setErrors] = useState<ManualInputErrors[]>([]);

  const address = isBorrowerAddress
    ? formData?.customBorrowerAddress
    : formData?.customAddress;

  const manualAddressInputsToValidate: {
    field: keyof AddressField;
    value: string;
  }[] = [
    { field: "street_number", value: address?.street_number ?? "" },
    { field: "street_name", value: address?.street_name ?? "" },
    { field: "street_suffix", value: address?.street_suffix ?? "" },
    { field: "secondary", value: address?.secondary ?? "" },
    { field: "city", value: address?.city ?? "" },
    { field: "state", value: address?.state ?? "" },
    { field: "zipcode", value: address?.zipcode ?? "" },
  ];

  const onValueChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { id, value } = event.target;
    const field = id as AddressField;
    const newFormData = { ...formData };

    if (isBorrowerAddress) {
      newFormData.customBorrowerAddress = {
        ...newFormData.customBorrowerAddress,
        [field]: value || undefined,
      } as ManualAddressFields;
    } else {
      newFormData.customAddress = {
        ...newFormData.customAddress,
        [field]: value || undefined,
      } as ManualAddressFields;
    }

    setErrorMessage?.("");
    setError?.(false);
    setFormData(newFormData);
    validateManualAddressInputs({ field, value });
  };

  const validateManualAddressInputs = (
    inputs:
      | { field: keyof AddressField; value: string }[]
      | { field: keyof AddressField; value: string },
  ) => {
    const checks: ManualInputErrors[] = [
      {
        regex: /^.{1,45}$/,
        message: "The street name must be less than 45 letters or numbers",
        success: false,
        field: "street_name",
      },
      {
        regex: /^[0-9-]{1,6}$/,
        message: "The street number must be a number and less than 6 digits",
        success: false,
        field: "street_number",
      },
      {
        regex: /^[a-zA-Z]{1,6}$/,
        message:
          "The street suffix must be less than 6 letters (not numbers or special characters)",
        success: false,
        field: "street_suffix",
      },
      {
        regex: /^[A-Za-z\s]{1,45}$/,
        message:
          "City contain letters and spaces and must be less than 45 letters",
        success: false,
        field: "city",
      },
      {
        regex: /^\d{5}$/,
        message: "The zipcode must have 5 digits and only contain numbers",
        success: false,
        field: "zipcode",
      },
      {
        regex: /^[A-Z]{2}$/,
        message: "State must be abbreviated",
        success: false,
        field: "state",
      },
    ];

    const validationErrors: ManualInputErrors[] = [];

    const isArray = Array.isArray(inputs);

    if (isArray) {
      inputs.forEach(({ field, value }) => {
        const trimmedValue = value.trim();
        const currentChecks = checks?.filter((check) => check.field === field);

        if (!trimmedValue && field !== "secondary") {
          validationErrors.push({
            field: field as keyof ManualAddressFields,
            message: `${String(field).replace("_", " ")} is required`,
            success: false,
          });
          return;
        }

        currentChecks.forEach((currentCheck) => {
          if (currentCheck && !currentCheck.regex?.test(trimmedValue)) {
            validationErrors.push({
              field: field as keyof ManualAddressFields,
              message: currentCheck.message,
              success: false,
            });
          }
        });

        if (field === "state" && !validateState(trimmedValue)) {
          validationErrors.push({
            field: "state",
            message: "State is not valid",
            success: false,
          });
        }
      });
    } else {
      const currentErrors = [...errors];

      const filteredCurrentErrors = currentErrors.filter(
        (error) => error.field !== inputs.field,
      );

      const currentChecks = checks.filter(
        (check) => check.field === inputs.field,
      );

      currentChecks.forEach((currentCheck) => {
        const trimmedValue = inputs.value.trim();

        if (inputs.field === "state" && !validateState(trimmedValue)) {
          errors.push({
            field: "state",
            message: "State is not valid",
            success: false,
          });
        }

        if (!currentCheck.regex?.test(trimmedValue)) {
          filteredCurrentErrors.push({
            field: inputs.field as keyof ManualAddressFields,
            message: currentCheck.message,
            success: false,
          });
        }
      });

      validationErrors.push(...filteredCurrentErrors);
    }

    setErrors(validationErrors);

    return validationErrors;
  };

  const validateState = (state: string): boolean => {
    if (!state) return true;
    return statesList.includes(state.toUpperCase()) && state.length === 2;
  };

  return {
    errors,
    setErrors,
    onValueChange,
    manualAddressInputsToValidate,
    validateManualAddressInputs,
  };
};
