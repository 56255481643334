import { useCallback } from "react";
import { Occupancy } from "typedef/PrivateLabel";
import API from "utils/API";
interface Body {
  requestedLoanAmount?: number;
  initialDrawAmount?: number;
  incomeSources?: {
    salaryIncome?: number;
    selfEmploymentIncome?: number;
    socialIncome?: number;
    otherIncome?: number;
  };
  loanPurpose?: string;
  typeOfOwnershipType?: string;
  middleName?: string;
  suffix?: string;
  maritalStatus?: string;
  phoneNumber?: string;
  occupancyType?: Occupancy;
  creditScore?: number;
  loanBalance?: number;
  homeValue?: number;
  helocBalance?: number;
  judmentsAndLiensBalance?: number;
  estNetProceeds?: number;
  estPointsAndFees?: number;
  interestRate?: number;
  borrowerAddress?: {
    city?: string;
    state?: string;
    street_line?: string;
    secondary?: string;
    zipcode?: string;
    fullAddress?: string;
    countyFips?: string;
  };
  customBorrowerAddress?: {
    city: string;
    state: string;
    zipcode: string;
    entries: number;
    secondary?: string;
    street_line: string;
  };
  isB1Mobile?: boolean;
  isB2Mobile?: boolean;
  telemarketingConsentCheck?: boolean;
}

export const useUpdateLoanStatus = (handleError?: (error: string) => void) => {
  function getLoanIdByStorge() {
    const loanId = localStorage.getItem("DTCApplication__loanId");
    return loanId;
  }

  const puUpdateLoanStatus = useCallback(
    async (params: { body?: Body }) => {
      try {
        const { body } = params;
        const loanId = getLoanIdByStorge();

        if (!loanId) {
          return;
        }

        const response = await API.post<{ message: string }>({
          url: `/register-user/loan?loanId=${loanId}`,
          data: {
            dataUpdated: body,
          },
        });

        if ("error" in response) {
          if (handleError) {
            handleError(response.error);
          }
        } else {
          return response.data;
        }
      } catch (error) {
        if (handleError) {
          const err = error as string;
          handleError(err);
        }
      }
    },
    [handleError],
  );

  const updateLoanStatus = useCallback(
    async (params: { loanId: string; body?: Body }) => {
      const { loanId, body } = params;
      await API.post({
        url: `/save-to-loan/update-loan-status?loanId=${loanId}`,
        data: {
          ...body,
        },
      });
    },
    [],
  );

  return { updateLoanStatus, puUpdateLoanStatus, getLoanIdByStorge };
};
