import { Box, Typography } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import Occupancy from "typedef/Occupancy";
import { CSSProperties, useState, useEffect, useRef } from "react";
import { FormData } from "../../useGetStarted";
import AddressType from "typedef/Address";
import { PrimaryResidence } from "components/icons/PrimaryResidence";
import { InvestmentProperty } from "components/icons/InvestmentProperty";
import { SecondHome } from "components/icons/SecondHome";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import { useUpdateLoanStatus } from "screens/GetStarted/useUpdateLoanStatus";
import { addressToString } from "../Address";
import { InputAddress } from "../Address/InputAddress";
import { useHBManualAddress } from "components/HBManualAddress/useHBManualAddress";

export interface PropertyProps {
  onNext: () => void;
  onPrev: () => void;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

export const Property = ({
  onNext,
  onPrev,
  formData,
  setFormData,
}: PropertyProps) => {
  const { sizes } = useHomebridgeSizes();
  const [error, setError] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [currentActive, setCurrentActive] = useState(formData.occupancyType);
  const [isManualAddress, setIsManualAddress] = useState(false);
  const [showBorrowerAddress, setShowBorrowerAddress] = useState(false);
  const [hasSelectedAddress, setHasSelectedAddress] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const isBorrowerAddress = true;

  const [borrowerAddress, setBorrowerAddress] = useState<AddressType>({
    city: "",
    state: "",
    street_line: "",
    secondary: "",
    zipcode: "",
    entries: 0,
  });

  const propertyAddress = {
    city: formData?.city?.trim() || "",
    state: formData?.state?.trim() || "",
    street_line: formData?.street_line?.trim() || "",
    secondary: formData?.secondary?.trim() || "",
    zipcode: formData?.zipcode?.trim() || "",
    entries: formData.entries || 0,
  };

  const isCustomAddress: AddressType & {
    street_name?: string;
    street_number?: string;
    street_suffix?: string;
  } = {
    city: formData?.customAddress?.city?.trim() ?? "",
    state: formData?.customAddress?.state?.trim() ?? "",
    street_line:
      formData.customAddress?.street_number?.trim() &&
      formData.customAddress?.street_name?.trim()
        ? `${formData?.customAddress?.street_number?.trim()} ${formData?.customAddress?.street_name?.trim()}${
            formData?.customAddress?.street_suffix?.length > 0
              ? ` ${formData?.customAddress?.street_suffix?.trim()}`
              : ""
          }`
        : "",
    secondary: formData?.customAddress?.secondary?.trim() ?? "",
    zipcode: formData?.customAddress?.zipcode?.trim() ?? "",
    street_name: formData?.customAddress?.street_name?.trim() ?? "",
    street_number: formData?.customAddress?.street_number?.trim() ?? "",
    street_suffix: formData?.customAddress?.street_suffix?.trim() ?? "",
    entries: 0,
  };

  const isCustomBorrowerAddress: AddressType = {
    city: formData?.customBorrowerAddress?.city?.trim() || "",
    state: formData?.customBorrowerAddress?.state?.trim() || "",
    street_line:
      formData.customBorrowerAddress?.street_number?.trim() &&
      formData.customBorrowerAddress?.street_name?.trim()
        ? `${formData?.customBorrowerAddress?.street_number?.trim()} ${formData?.customBorrowerAddress?.street_name?.trim()}${
            (formData?.customBorrowerAddress?.street_suffix ?? "").length > 0
              ? ` ${formData?.customBorrowerAddress?.street_suffix?.trim()}`
              : ""
          }`
        : "",
    secondary: formData?.customBorrowerAddress?.secondary?.trim() || "",
    zipcode: formData?.customBorrowerAddress?.zipcode?.trim() || "",
    entries: 0,
  };

  const {
    errors,
    setErrors,
    manualAddressInputsToValidate,
    validateManualAddressInputs,
    onValueChange,
  } = useHBManualAddress({
    formData,
    setFormData,
    setErrorMessage,
    setError,
    isBorrowerAddress,
  });

  const valueInputRef = useRef<HTMLInputElement>(null);

  const handleLoanStatusError = (error: string) => {
    setErrorMessage(error);
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
    }, 300);
  };

  useEffect(() => {
    if (
      currentActive === "Second Home" ||
      currentActive === "Investment Property"
    ) {
      setShowBorrowerAddress(true);
      setTimeout(() => {
        valueInputRef.current?.focus();
      }, 100);
    } else {
      setShowBorrowerAddress(false);
      setIsManualAddress(false);
    }
  }, [currentActive]);

  const { puUpdateLoanStatus } = useUpdateLoanStatus(handleLoanStatusError);

  const handleNext = async () => {
    setErrorMessage("");
    const isBorrowerAddressUsed =
      formData.occupancyType === "Second Home" ||
      formData.occupancyType === "Investment Property";

    if (!formData.occupancyType) {
      setAnimation(true);
      setError(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      if (
        isBorrowerAddressUsed &&
        !borrowerAddress?.street_line &&
        !hasSelectedAddress
      ) {
        setAnimation(true);
        setError(true);
        setErrorMessage("Please enter a property");
        valueInputRef.current?.focus();
        setTimeout(() => {
          setAnimation(false);
        }, 300);
      } else if (hasSelectedAddress && isManualAddress) {
        const inputErrors = validateManualAddressInputs(
          manualAddressInputsToValidate,
        );

        if (inputErrors?.length > 0) {
          setErrors([...inputErrors]);
          setAnimation(true);
          setTimeout(() => {
            setAnimation(false);
          }, 300);
          return;
        } else {
          setErrorMessage("");
          const address =
            isCustomAddress.street_line.length > 0
              ? isCustomAddress
              : propertyAddress;

          const formatedAddress = addressToString(address).toLowerCase();
          const formatedCustomBorrowerAddress = addressToString(
            isCustomBorrowerAddress,
          ).toLowerCase();

          const isSameAddress =
            formatedCustomBorrowerAddress === formatedAddress;

          if (isSameAddress) {
            setAnimation(true);
            setError(true);
            setErrorMessage(
              "Sub property address and primary home address cannot be the same",
            );
            valueInputRef.current?.focus();
            setTimeout(() => {
              setAnimation(false);
            }, 300);
          } else {
            setError(false);
            setLoading(true);
            const data = await puUpdateLoanStatus({
              body: {
                occupancyType: formData.occupancyType,
                borrowerAddress: undefined,
                customBorrowerAddress: isCustomBorrowerAddress,
              },
            });

            if (data?.message === "Loan status updated.") {
              onNext();
            }
            setLoading(false);
          }
        }
      } else {
        setErrorMessage("");

        const address =
          isCustomAddress.street_line.length > 0
            ? isCustomAddress
            : propertyAddress;

        const formatedAddress = addressToString(address).toLowerCase();
        const formatedBorrowerAddress =
          addressToString(borrowerAddress).toLowerCase();

        const isSameAddress = formatedAddress === formatedBorrowerAddress;

        if (isSameAddress) {
          setAnimation(true);
          setError(true);
          setErrorMessage(
            "Sub property address and primary home address cannot be the same",
          );
          valueInputRef.current?.focus();
          setTimeout(() => {
            setAnimation(false);
          }, 300);
        } else {
          setError(false);
          setLoading(true);

          setFormData({
            ...formData,
            borrowerAddress: borrowerAddress,
            customBorrowerAddress: undefined,
          });
          const data = await puUpdateLoanStatus({
            body: {
              occupancyType: formData.occupancyType,
              borrowerAddress: borrowerAddress,
              customBorrowerAddress: undefined,
            },
          });

          if (data?.message === "Loan status updated.") {
            onNext();
          }
          setLoading(false);
        }
      }
    }
  };

  const handleSelect = (type: Occupancy) => {
    setErrorMessage("");
    setFormData({ ...formData, occupancyType: type });
    setCurrentActive(type);
    setError(false);
  };

  const descriptionStyles: CSSProperties = {
    textTransform: "capitalize",
    marginTop: "0.45rem",
  };
  return (
    <Box>
      <Box marginTop={"60px"}>
        <Box
          sx={{
            display: "grid",
            justifyItems: "center",
            gridTemplateColumns: {
              xs: "",
              md: "repeat(3, 1fr)",
            },
            justifyContent: {
              xs: "",
              md: "center",
            },
            gap: "20px",
          }}
        >
          <CHBButton
            onClick={() => handleSelect("Primary Residence")}
            classNameContainer="full-width"
            className={`animated-button ${
              currentActive === "Primary Residence"
                ? "animated-button--active"
                : ""
            }`}
            styleContainer={{
              height: "100%",
              width: "100%",
            }}
            style={{
              border:
                error &&
                errorMessage === "" &&
                errors.length === 0 &&
                !isManualAddress
                  ? "2px solid red"
                  : "2px solid #20A2D8",
              animation:
                animation &&
                errorMessage === "" &&
                errors.length === 0 &&
                !isManualAddress
                  ? "shake 0.3s ease-in-out"
                  : "none",
              height: "100%",
              padding: "20px",
            }}
          >
            <PrimaryResidence />
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.typography}
              fontWeight={"bold"}
              marginBottom={"10px"}
              className="custom-typography text-black"
              sx={descriptionStyles}
            >
              Full-Time
              <br /> Residence
            </Typography>
          </CHBButton>
          <CHBButton
            onClick={() => handleSelect("Second Home")}
            classNameContainer="full-width"
            className={`animated-button ${
              currentActive === "Second Home" ? "animated-button--active" : ""
            }`}
            styleContainer={{
              height: "100%",
              width: "100%",
            }}
            style={{
              border:
                error &&
                errorMessage === "" &&
                errors.length === 0 &&
                !isManualAddress
                  ? "2px solid red"
                  : "2px solid #20A2D8",
              animation:
                animation &&
                errorMessage === "" &&
                errors.length === 0 &&
                !isManualAddress
                  ? "shake 0.3s ease-in-out"
                  : "none",
              height: "100%",
              padding: "20px",
            }}
          >
            <SecondHome />
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.typography}
              fontWeight={"bold"}
              marginBottom={"10px"}
              className="custom-typography text-black"
              sx={descriptionStyles}
            >
              Second <br />
              Home
            </Typography>
          </CHBButton>
          <CHBButton
            onClick={() => handleSelect("Investment Property")}
            classNameContainer="full-width"
            className={`animated-button ${
              currentActive === "Investment Property"
                ? "animated-button--active"
                : ""
            }`}
            styleContainer={{
              height: "100%",
              width: "100%",
            }}
            style={{
              border:
                error &&
                errorMessage === "" &&
                errors.length === 0 &&
                !isManualAddress
                  ? "2px solid red"
                  : "2px solid #20A2D8",
              animation:
                animation &&
                errorMessage === "" &&
                errors.length === 0 &&
                !isManualAddress
                  ? "shake 0.3s ease-in-out"
                  : "none",
              height: "100%",
              padding: "20px",
            }}
          >
            <InvestmentProperty />
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.typography}
              fontWeight={"bold"}
              marginBottom={"10px"}
              className="custom-typography text-black"
              sx={descriptionStyles}
            >
              Investment <br />
              Property
            </Typography>
          </CHBButton>
        </Box>
        <p
          className="get-started__note"
          style={{ marginTop: "40px", marginBottom: "30px" }}
        >
          Knowing this will help us determine your available line of credit.
        </p>
        {showBorrowerAddress && (
          <Box
            sx={{
              fontFamily: "NunitoSans",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InputAddress
              formData={formData}
              inputLabel="Please enter the address of the home you currently live in."
              currentAddress={borrowerAddress}
              setCurrentAddress={setBorrowerAddress}
              valueInputRef={valueInputRef}
              error={error}
              setError={setError}
              animation={animation}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              addressToString={addressToString}
              hasSelectedAddress={hasSelectedAddress}
              setHasSelectedAddress={setHasSelectedAddress}
              isManualAddress={isManualAddress}
              setIsManualAddress={setIsManualAddress}
              isBorrowerAddress={true}
              errors={errors}
              onValueChange={onValueChange}
            />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column-reverse",
              sm: "row",
            },
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "40px",
          }}
        >
          <CHBButton onClick={onPrev} secondaryArrow={true} disabled={loading}>
            Go Back
          </CHBButton>
          <CHBButton
            onClick={handleNext}
            loading={loading}
            disabled={loading}
            ctaId="Step2PropertyTaken"
          >
            Next
          </CHBButton>
        </Box>
      </Box>
      <HBSeparate className="step-separate" type="vertical" />
    </Box>
  );
};
