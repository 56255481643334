import { useCallback, useEffect, useRef, useState } from "react";
import { PropertyProps } from "../Property";
import { FormData, HELOCOffer, OfferResponse } from "../../useGetStarted";
import API, { ResponseError } from "utils/API";
import { useUpdateLoanStatus } from "screens/GetStarted/useUpdateLoanStatus";
import Loan from "typedef/Loan";

export interface OfferProps extends PropertyProps {
  offer?: OfferResponse;
  loading?: boolean;
  error?: string | null;
  loan?: Loan;
  setGlobalError: React.Dispatch<React.SetStateAction<string | null>>;
  setLoadingPage: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CurrentOffer extends HELOCOffer {
  index: number;
}

interface UseOfferProps {
  onNext: () => void;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  setGlobalError: React.Dispatch<React.SetStateAction<string | null>>;
  setLoadingPage: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useOffer = ({
  formData,
  setFormData,
  onNext,
  setGlobalError,
  setLoadingPage,
}: UseOfferProps) => {
  const { updateLoanStatus } = useUpdateLoanStatus();
  const [offer, setOffer] = useState<OfferResponse | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const [loadingOffer, setLoadingOffer] = useState(true);
  const [refreshOffer, setRefreshOffer] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [currentActive, setCurrentActive] = useState<CurrentOffer>();
  const [editRequestLoanAmount, setEditRequestLoanAmount] = useState(false);
  const [editAnimation, setEditAnimation] = useState(false);

  const handleNext = () => {
    if (editRequestLoanAmount) {
      setEditAnimation(true);
      setTimeout(() => {
        setEditAnimation(false);
      }, 300);
      return;
    }

    if (!currentActive) {
      setAnimation(true);
      setError(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      setError(false);
      updateLoanStatus({
        loanId: formData.loanId as string,
        body: {
          requestedLoanAmount: formData.requestedLoanAmount,
          initialDrawAmount: formData.initialDrawAmount,
          estNetProceeds: formData.initialDrawAmount
            ? formData.initialDrawAmount - currentActive?.estPointsAndFees
            : currentActive.estNetProceeds,
          estPointsAndFees: currentActive?.estPointsAndFees,
          interestRate: currentActive?.interestRate,
        },
      });
      onNext();
    }
  };

  const handleSelect = useCallback(
    (index: number) => {
      if (index === currentActive?.index) {
        return;
      }

      if (editRequestLoanAmount) {
        setEditAnimation(true);
        setTimeout(() => {
          setEditAnimation(false);
        }, 300);
        return;
      }
      const currentOffer = offer?.items?.[index];
      if (currentOffer) {
        setFormData({
          ...formData,
          requestedLoanAmount: currentOffer.amount,
          initialDrawAmount: currentOffer.amount,
        });
        setCurrentActive({ ...currentOffer, index });
        setError(false);
      }
    },
    [
      currentActive?.index,
      editRequestLoanAmount,
      formData,
      offer?.items,
      setFormData,
    ],
  );

  const loadedOfferRef = useRef(false);

  const getOffer = useCallback(
    async (refresh?: boolean, e?: React.MouseEvent<HTMLButtonElement>) => {
      setErrorMessage(null);
      if (e?.preventDefault) {
        e.preventDefault();
        setLoadingOffer(true);
      }

      if (refresh) {
        setRefreshOffer(true);
      } else {
        setLoadingOffer(true);
        setLoadingPage(true);
      }
      setError(false);
      try {
        const url = `/save-to-loan/generate-offers?loanId=${formData.loanId}`;
        const response = await API.post<OfferResponse>({
          url,
          data: {
            ...formData,
            state: formData.customAddress?.state ?? formData.state,
          },
        });
        if ("error" in response) {
          const responseError = response?.error as unknown as ResponseError;
          if (responseError?.errorId?.message) {
            setErrorMessage(responseError?.errorId?.message);
            setGlobalError(responseError?.errorId?.message);
            setError(true);
            setLoadingOffer(false);
            setLoadingPage(false);
            setRefreshOffer(false);
            return;
          } else {
            setErrorMessage(response.error);
            setGlobalError(response.error);
            setError(true);
            setLoadingOffer(false);
            setLoadingPage(false);
            setRefreshOffer(false);
            return;
          }
        } else {
          if (refresh) {
            const newOffer = response.data.items[0];
            const currentItems = offer?.items;

            if (currentItems) {
              const getMaxAmount = offer?.items.filter(
                (item) => item.maxAmount > newOffer.amount,
              );

              currentItems[currentActive?.index ?? 0] = {
                ...newOffer,
                maxAmount:
                  getMaxAmount?.[0]?.maxAmount ??
                  currentActive?.maxAmount ??
                  newOffer?.maxAmount,
              };
              const newOfferResponse = {
                ...offer,
                items: currentItems,
              };
              setOffer(newOfferResponse);
              setEditRequestLoanAmount(false);
            } else {
              setOffer(response.data);
            }
          } else {
            setOffer(response.data);
          }
          setLoadingOffer(false);
          setLoadingPage(false);
          setRefreshOffer(false);
        }
      } catch (error) {
        console.error(error);
        setErrorMessage("An error occurred, please try again later.");
        setGlobalError("An error occurred, please try again later.");
        setLoadingOffer(false);
        setLoadingPage(false);
        setRefreshOffer(false);
      }
    },
    [
      formData,
      currentActive?.index,
      currentActive?.maxAmount,
      offer,
      setGlobalError,
      setLoadingPage,
    ],
  );

  useEffect(() => {
    if (!loadedOfferRef.current && formData?.loanId) {
      getOffer();
      loadedOfferRef.current = true;
    }
  }, [getOffer, formData?.loanId]);

  useEffect(() => {
    if (offer?.items?.length === 1) {
      handleSelect(0);
    }
  }, [handleSelect, offer?.items]);

  return {
    offer,
    getOffer,
    loadingOffer,
    refreshOffer,
    error,
    handleSelect,
    currentActive,
    errorMessage,
    animation,
    editAnimation,
    handleNext,
    editRequestLoanAmount,
    setEditRequestLoanAmount,
  };
};
