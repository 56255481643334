export const env = {
  DEV: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev",
    TALKJS_APP_ID: "tgga7aVE",
  },
  DANTE: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://i5vp0pn9a8.execute-api.us-east-1.amazonaws.com/dev-dante`,
    API_ADMIN_URL: `https://in4gtzyli7.execute-api.us-east-1.amazonaws.com/dev-dante`,
    API_LO_URL: `https://86j81z6xx6.execute-api.us-east-1.amazonaws.com/dev-dante`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-dante",
    TALKJS_APP_ID: "tgga7aVE",
  },
  FRAN_CAJAL: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://2f5pcb7uta.execute-api.us-east-1.amazonaws.com/dev-fran`,
    API_ADMIN_URL: `https://qqlk79knaj.execute-api.us-east-1.amazonaws.com/dev-fran`,
    API_LO_URL: `https://si0ylryix7.execute-api.us-east-1.amazonaws.com/dev-fran`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-fran",
    TALKJS_APP_ID: "tgga7aVE",
  },
  ROQUE: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://upqg5rpyof.execute-api.us-east-1.amazonaws.com/dev-roque`,
    API_ADMIN_URL: `https://izqn0qn7md.execute-api.us-east-1.amazonaws.com/dev-roque`,
    API_LO_URL: `https://iz0oucn123.execute-api.us-east-1.amazonaws.com/dev-roque`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-roque",
    TALKJS_APP_ID: "tgga7aVE",
  },
  LEO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://9b6hncp8cc.execute-api.us-east-1.amazonaws.com/dev-leo`,
    API_ADMIN_URL: `https://aq11qbtix6.execute-api.us-east-1.amazonaws.com/dev-leo`,
    API_LO_URL: `https://8wsua8x475.execute-api.us-east-1.amazonaws.com/dev-leo`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-leo",
    TALKJS_APP_ID: "tgga7aVE",
  },
  LU: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://x4au91troe.execute-api.us-east-1.amazonaws.com/dev-lu`,
    API_ADMIN_URL: `https://23qtqb2efa.execute-api.us-east-1.amazonaws.com/dev-lu`,
    API_LO_URL: `https://3e4txdssgg.execute-api.us-east-1.amazonaws.com/dev-lu`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-lu",
    TALKJS_APP_ID: "tgga7aVE",
  },
  JUSTO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://drqy441gud.execute-api.us-east-1.amazonaws.com/dev-justo`,
    API_ADMIN_URL: `https://1ic61g1ef7.execute-api.us-east-1.amazonaws.com/dev-justo`,
    API_LO_URL: `https://ewzqlp2x1a.execute-api.us-east-1.amazonaws.com/dev-justo`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-justo",
    TALKJS_APP_ID: "tgga7aVE",
  },
  LEANDRO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://ya2db7tapa.execute-api.us-east-1.amazonaws.com/dev-leandro`,
    API_ADMIN_URL: `https://opgepj5xo8.execute-api.us-east-1.amazonaws.com/dev-leandro`,
    API_LO_URL: `https://u1g8g2bhqh.execute-api.us-east-1.amazonaws.com/dev-leandro`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-leandro",
    TALKJS_APP_ID: "tgga7aVE",
  },
  FRANCO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://lzp3grysgc.execute-api.us-east-1.amazonaws.com/dev-franco`,
    API_ADMIN_URL: `https://wfflrcfa0f.execute-api.us-east-1.amazonaws.com/dev-franco`,
    API_LO_URL: `https://phl8572j63.execute-api.us-east-1.amazonaws.com/dev-franco`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-franco",
    TALKJS_APP_ID: "tgga7aVE",
  },
  JOAQUIN: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://yf7l64c70j.execute-api.us-east-1.amazonaws.com/dev-joaquin`,
    API_ADMIN_URL: `https://jwd6kvwg3l.execute-api.us-east-1.amazonaws.com/dev-joaquin`,
    API_LO_URL: `https://00lov29pj4.execute-api.us-east-1.amazonaws.com/dev-joaquin`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-joaquin",
    TALKJS_APP_ID: "tgga7aVE",
  },
  WALY: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://ge7c3vu658.execute-api.us-east-1.amazonaws.com/dev-waly`,
    API_ADMIN_URL: `https://0ho2g81nue.execute-api.us-east-1.amazonaws.com/dev-waly`,
    API_LO_URL: `https://2wf4eq743k.execute-api.us-east-1.amazonaws.com/dev-waly`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-waly",
    TALKJS_APP_ID: "tgga7aVE",
  },
  ALEJO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://top8ysjlye.execute-api.us-east-1.amazonaws.com/dev-alejo`,
    API_ADMIN_URL: `https://inl3kmgix0.execute-api.us-east-1.amazonaws.com/dev-alejo`,
    API_LO_URL: `https://kccn4cqdcl.execute-api.us-east-1.amazonaws.com/dev-alejo`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-alejo",
    TALKJS_APP_ID: "tgga7aVE",
  },
  SIMON: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://wvscbx9k0c.execute-api.us-east-1.amazonaws.com/dev-simon`,
    API_ADMIN_URL: `https://4n17fcn6cd.execute-api.us-east-1.amazonaws.com/dev-simon`,
    API_LO_URL: `https://5slz5omj31.execute-api.us-east-1.amazonaws.com/dev-simon`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-simon",
    TALKJS_APP_ID: "tgga7aVE",
  },
  NICOLAS: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://ctu8fx8e34.execute-api.us-east-1.amazonaws.com/dev-nicolas`,
    API_ADMIN_URL: `https://73op81fnf7.execute-api.us-east-1.amazonaws.com/dev-nicolas`,
    API_LO_URL: `https://5pbxqdd0lk.execute-api.us-east-1.amazonaws.com/dev-nicolas`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-nicolas",
    TALKJS_APP_ID: "tgga7aVE",
  },
  ESTEBAN: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://725h9c7euj.execute-api.us-east-1.amazonaws.com/dev-esteban`,
    API_ADMIN_URL: `https://k1kvbqdaqd.execute-api.us-east-1.amazonaws.com/dev-esteban`,
    API_LO_URL: `https://7iksp5i5ca.execute-api.us-east-1.amazonaws.com/dev-esteban`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-esteban",
    TALKJS_APP_ID: "tgga7aVE",
  },
  SHANE: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://h6o4wckahb.execute-api.us-east-1.amazonaws.com/dev-shane`,
    API_ADMIN_URL: `https://lyc0rmawjf.execute-api.us-east-1.amazonaws.com/dev-shane`,
    API_LO_URL: `https://yb9wyg3fue.execute-api.us-east-1.amazonaws.com/dev-shane`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-shane",
    TALKJS_APP_ID: "tgga7aVE",
  },
  JUANI: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://dp7b4701mf.execute-api.us-east-1.amazonaws.com/dev-juani`,
    API_ADMIN_URL: `https://16ufoeolme.execute-api.us-east-1.amazonaws.com/dev-juani`,
    API_LO_URL: `https://qts9ijb9yi.execute-api.us-east-1.amazonaws.com/dev-juani`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-juani",
    TALKJS_APP_ID: "tgga7aVE",
  },
  PABLOBA: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://d9yichnlu9.execute-api.us-east-1.amazonaws.com/dev-pabloba`,
    API_ADMIN_URL: `https://6fbbieyfdh.execute-api.us-east-1.amazonaws.com/dev-pabloba`,
    API_LO_URL: `https://fcill65hv5.execute-api.us-east-1.amazonaws.com/dev-pabloba`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-pabloba",
    TALKJS_APP_ID: "tgga7aVE",
  },
  JULIAN: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://msuw6vujri.execute-api.us-east-1.amazonaws.com/dev-julian`,
    API_ADMIN_URL: `https://uthdkho2pe.execute-api.us-east-1.amazonaws.com/dev-julian`,
    API_LO_URL: `https://j1mi3bwth0.execute-api.us-east-1.amazonaws.com/dev-julian`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-julian",
    TALKJS_APP_ID: "tgga7aVE",
  },
  MELANY: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://i29x6c534c.execute-api.us-east-1.amazonaws.com/dev-melany`,
    API_ADMIN_URL: `https://c1dw35at12.execute-api.us-east-1.amazonaws.com/dev-melany`,
    API_LO_URL: `https://immo2xjuy9.execute-api.us-east-1.amazonaws.com/dev-melany`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-melany",
    TALKJS_APP_ID: "tgga7aVE",
  },
  MATIAS: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://5yos6rk9e1.execute-api.us-east-1.amazonaws.com/dev-matias`,
    API_ADMIN_URL: `https://3bbwwqrpxg.execute-api.us-east-1.amazonaws.com/dev-matias`,
    API_LO_URL: `https://xdlce3cp45.execute-api.us-east-1.amazonaws.com/dev-matias`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-matias",
    TALKJS_APP_ID: "tgga7aVE",
  },
  MAXI: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://kohksqyxn7.execute-api.us-east-1.amazonaws.com/dev-maxi`,
    API_ADMIN_URL: `https://c6eb4b5u42.execute-api.us-east-1.amazonaws.com/dev-maxi`,
    API_LO_URL: `https://o9kxk8s7l3.execute-api.us-east-1.amazonaws.com/dev-maxi`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-maxi",
    TALKJS_APP_ID: "tgga7aVE",
  },
  ARIEL: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://47xed19rd1.execute-api.us-east-1.amazonaws.com/dev-ariel`,
    API_ADMIN_URL: `https://576clewkhl.execute-api.us-east-1.amazonaws.com/dev-ariel`,
    API_LO_URL: `https://wkz2gzw5j3.execute-api.us-east-1.amazonaws.com/dev-ariel`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-ariel",
    TALKJS_APP_ID: "tgga7aVE",
  },
  LUIS: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://bdogvxh84k.execute-api.us-east-1.amazonaws.com/dev-luis`,
    API_ADMIN_URL: `https://zjjc99x9u0.execute-api.us-east-1.amazonaws.com/dev-luis`,
    API_LO_URL: `https://h13bfv8kil.execute-api.us-east-1.amazonaws.com/dev-luis`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-luis",
    TALKJS_APP_ID: "tgga7aVE",
  },
  NICOLASF: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://2qhpxqt3a3.execute-api.us-east-1.amazonaws.com/dev-nicolasf`,
    API_ADMIN_URL: `https://jlmkw8fl36.execute-api.us-east-1.amazonaws.com/dev-nicolasf`,
    API_LO_URL: `https://z49insfbjc.execute-api.us-east-1.amazonaws.com/dev-nicolasf`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-nicolasf",
    TALKJS_APP_ID: "tgga7aVE",
  },
  GABRIEL: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://gkykyskeyl.execute-api.us-east-1.amazonaws.com/dev-gabriel`,
    API_ADMIN_URL: `https://h7u6tpzujd.execute-api.us-east-1.amazonaws.com/dev-gabriel`,
    API_LO_URL: `https://ljwyarkz37.execute-api.us-east-1.amazonaws.com/dev-gabriel`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-gabriel",
    TALKJS_APP_ID: "tgga7aVE",
  },
  SANTI: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://qi4swcaa8h.execute-api.us-east-1.amazonaws.com/dev-santi`,
    API_ADMIN_URL: `https://udp0wuqop2.execute-api.us-east-1.amazonaws.com/dev-santi`,
    API_LO_URL: `https://rzm1kevta5.execute-api.us-east-1.amazonaws.com/dev-santi`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-santi",
    TALKJS_APP_ID: "tgga7aVE",
  },
  ORNELLA: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://ltatrln2hh.execute-api.us-east-1.amazonaws.com/dev-orne`,
    API_ADMIN_URL: `https://4qofdyt113.execute-api.us-east-1.amazonaws.com/dev-orne`,
    API_LO_URL: `https://cafe1xsy5g.execute-api.us-east-1.amazonaws.com/dev-orne`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-orne",
    TALKJS_APP_ID: "tgga7aVE",
  },
  PROD: {
    LO_FRONT_URL: "https://lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/prod`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/prod`,
    API_LO_URL: `https://api.lo.nftydoor.com/prod`,
    SMARTY_KEY: "118833480137188122",
    ENV: "prod",
    TALKJS_APP_ID: "tgga7aVE",
  },
};

export const ENV = ((localStorage.getItem("currentEviroment") as string) ??
  "DEV") as keyof typeof env;

function getEnvVariable(key: keyof typeof env.DEV) {
  const isInProduction = process.env.REACT_APP_ENV === "prod";
  return env[isInProduction ? "PROD" : ENV][key];
}

export default getEnvVariable;
