import { AutocompleteValue } from "@mui/material";
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
} from "@mui/material/Autocomplete";
import getEnvVariable from "env";
import {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Address from "typedef/Address";
import addressToString from "./addressToString";
import sanitizeString from "./sanitizeString";

export interface AddressAutocompleteHookParams {
  address?: Address | null;
  setAddress: Dispatch<Address | null>;
  isShowManualAddress?: boolean;
}

const useAddressAutoComplete = ({
  address,
  setAddress,
  isShowManualAddress,
}: AddressAutocompleteHookParams) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const searchTimeout = useRef<NodeJS.Timeout>();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Address[]>([]);
  const selectedAddressWithSecondary = useRef<Address>();
  const API_LO_URL = getEnvVariable("API_URL");

  const addressNotListed = useMemo(
    () => ({
      street_line: "My address is not listed",
      city: "",
      state: "",
      zipcode: "",
      entries: 0,
    }),
    [],
  );

  const handleOnChange = useCallback(
    (
      _event: React.SyntheticEvent,
      value: AutocompleteValue<Address, undefined, undefined, undefined>,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<Address>,
    ) => {
      if (value) setAddress(value);
    },
    [setAddress],
  );

  const handleOnInputChange = useCallback(
    (
      _event: React.SyntheticEvent,
      value: string,
      _reason: AutocompleteInputChangeReason,
    ) => {
      setText(value);
      if (value === "" && address) {
        setAddress(null);
      }
      if (selectedAddressWithSecondary.current) {
        const selectedAddressWithSecondaryStreetLine =
          selectedAddressWithSecondary.current.street_line;
        if (!selectedAddressWithSecondaryStreetLine?.includes(value)) {
          selectedAddressWithSecondary.current = undefined;
        }
      }
      if (searchTimeout.current) clearTimeout(searchTimeout.current);
      const sanitized = sanitizeString(value);
      if (sanitized) {
        searchTimeout.current = setTimeout(async () => {
          try {
            setLoading(true);
            if (selectedAddressWithSecondary.current) {
              const smartyResponse = await fetch(
                `${API_LO_URL}/getServiceUnsecured/smarty-autocomplete?search=${encodeURIComponent(
                  sanitized.replace(/ /g, "+"),
                )}&suggestion=${encodeURIComponent(
                  addressToString(selectedAddressWithSecondary.current).replace(
                    / /g,
                    "+",
                  ),
                )}`,
                {
                  method: "GET",
                },
              );
              const { body } = await smartyResponse.json();

              const suggestions = JSON.parse(body).suggestions;
              setOptions(
                isShowManualAddress
                  ? [addressNotListed, ...suggestions]
                  : suggestions,
              );
            } else {
              const smartyResponse = await fetch(
                `${API_LO_URL}/getServiceUnsecured/smarty-autocomplete?search=${encodeURIComponent(
                  sanitized,
                )}`,
                {
                  method: "GET",
                },
              );

              const { body } = await smartyResponse.json();

              const suggestions = JSON.parse(body).suggestions;
              setOptions(
                isShowManualAddress
                  ? [addressNotListed, ...suggestions]
                  : suggestions,
              );
            }
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        }, 1000);
      }
    },
    [
      selectedAddressWithSecondary,
      address,
      setAddress,
      API_LO_URL,
      isShowManualAddress,
      addressNotListed,
    ],
  );

  useEffect(() => {
    if (address?.secondary && address.entries > 1) {
      const newTextSearch = addressToString(address)?.split(" (")[0];
      selectedAddressWithSecondary.current = address;
      setOptions([]);
      setOpen(true);
      setLoading(true);
      setText(newTextSearch);
      fetch(
        `${API_LO_URL}/getServiceUnsecured/smarty-autocomplete?search=${encodeURIComponent(
          newTextSearch.replace(/ /g, "+"),
        )}&suggestion=${encodeURIComponent(
          addressToString(address).replace(/ /g, "+"),
        )}`,
        {
          method: "GET",
        },
      )
        .then((res) => res.json())
        .then((res: { body: string }) => {
          const suggestions = JSON.parse(res.body).suggestions;
          setOptions(
            isShowManualAddress
              ? [addressNotListed, ...suggestions]
              : suggestions,
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (address) {
      selectedAddressWithSecondary.current = undefined;
    }
  }, [address, API_LO_URL, isShowManualAddress, addressNotListed]);

  const handleOnOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleOnClose = useCallback(
    (_event: React.SyntheticEvent, _reason: AutocompleteCloseReason) => {
      setOpen(false);
    },
    [],
  );

  return {
    text,
    setText,
    open,
    options,
    loading,
    handleOnOpen,
    handleOnClose,
    handleOnChange,
    handleOnInputChange,
  };
};

export default useAddressAutoComplete;
